<template>
  <div>
    <div class="pb-4">
      <div class="game">
        <router-link :to="`game-details/${gameDetails.id}`">
          <div class="game-overlay"></div>
          <img
            v-if="gameDetails.GImage === null"
            src="../assets/images/default-thumbnail.png"
            class="w-100"
            alt=""
          />
          <img v-else :src="gameDetails.GImage" class="w-100" alt="" />
          <div class="game-category">{{ gameDetails.Category || "N/A" }}</div>
          <div class="game-details fadeIn-bottom">
            <h3 class="game-title">Download</h3>
            <p class="game-text"><i class="fa fa-eye"></i> 14</p>
          </div>
        </router-link>
        <!-- <a href="./games-details.html"> </a> -->
        <div class="d-flex justify-content-between pb-3 px-1">
          <div class="text-truncate font-weight-bold">
            {{ gameDetails.Gname }}
          </div>
          <div class="game-size">{{ gameDetails.Size || "N/A" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["gameDetails"],
};
</script>
