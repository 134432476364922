<template>
  <div>
    <div class="pb-4">
      <span
        class="skeleton-box"
        :style="`height: ${height}`"
        style="width: 100%"
      ></span>
      <span class="skeleton-box pb-3" style="width: 100%"></span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["height"],
};
</script>
