<template>
  <div>
    <header class="game all-games">
      <div class="container h-100 px-4 mb-4 text-center">
        <div class="row h-100 d-flex justify-content-center align-items-center">
          <div class="col-lg-12">
            <h1 class="text-uppercase">All Games</h1>
            <div class="horizontal-divider"></div>
          </div>
        </div>
      </div>
    </header>

    <section class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p class="text-left pb-5">
              Choose from exciting Android games on these categories:
              Simulation, Action, Racing, Adventure Sports and Role Playing!
            </p>
          </div>
        </div>
        <div class="row" v-if="games.length <= 0">
          <div class="col-lg-4" v-for="i in 9" :key="i">
            <SkeletonThumbnail height="200px" />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-4" v-for="(game, idx) in games" :key="idx">
            <GameThumbnail :gameDetails="game" />
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-md-2 offset-md-5">
            <button class="btn btn-sm btn-candy-grape btn-block">
              Load more
            </button>
          </div>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
import GameThumbnail from "../components/GameThumbnail.vue";
import SkeletonThumbnail from "../components/SkeletonThumbnail.vue";
export default {
  components: {
    GameThumbnail,
    SkeletonThumbnail,
  },

  data() {
    return {
      games: [],
    };
  },

  methods: {
    async getGames() {
      await this.$api
        .get("/funbox?country=BD")
        .then((response) => {
          // console.log(response);
          this.games = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  created() {
    this.getGames();
  },
};
</script>
